<template>
  <div>
    <h3>{{ $t('pages.document_designer.general') }}</h3>
    <paper-size />
    <font />
    <pdf-title title="Delivery Note" />
    <date-format />
  </div>
</template>

<script>
import PaperSize from '../../components/control/components/paper-size.vue'
import Font from '../../components/control/components/font.vue'
import PdfTitle from '../../components/control/components/title.vue'
import DateFormat from '../../components/control/components/date-format.vue'

export default {
  components: {
    PaperSize,
    Font,
    PdfTitle,
    DateFormat
  }
}
</script>
